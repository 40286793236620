import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const TermsofusePage = () => (
  <Layout>
    <Seo title="Terms of Use" />
    <main className="p-10 mx-auto space-y-4 max-w-screen-xl">
      <h1 className="text-2xl font-bold">Terms of Use</h1>

      <p>
        These Terms and Conditions of Use govern your use of this website. By
        using this website. you agree to these terms and conditions of use. By
        accepting the terms contained herein, you are indicating your
        acknowledgment and acceptance of these Terms and Conditions of Use.
        Changes in the terms and conditions of use may occur at any time,
        without further notice.
      </p>
      <p>
        Your use of this site after such changes are implemented constitutes
        your acknowledgment and acceptance of the changes. Before each use,
        please consult these Terms and Conditions of Use carefully. This website
        is provided by Daniel Mickle Insurance (hereafter referred to as “Mickle
        Senior Benefits”). These Terms and Conditions apply exclusively to your
        access to, and use of, this website and does not alter in any way the
        terms or conditions of any other agreement you may have with Mickle
        Senior Benefits.
      </p>
      <h2 className="text-xl font-semibold mt-2">Privacy Policy</h2>

      <p>
        Mickle Senior Benefits has adopted a Privacy Policy. You are encouraged
        to refer to the Privacy Policy by clicking on the{" "}
        <strong>
          <Link to="/privacy">Privacy Policy link </Link>
        </strong>
        before providing any information.
      </p>
      <h2 className="text-xl font-semibold mt-2">Consent To Be Contacted</h2>

      <p>
        You understand that by submitting your personal information, you are
        making a consumer inquiry for insurance offered by licensed insurance
        agents. You also understand that by doing so, you consent and expect to
        be contacted by any and all such licensed insurance agents via
        telephone, email, SMS text, or direct mail even if you are registered on
        the federal do-not-call list and/or any applicable state do-not-call
        list. You may withdraw consent by notifying Mickle Senior Benefits by
        phone or email.
      </p>
      <h2 className="text-xl font-semibold mt-2">
        Representations and Warranties
      </h2>

      <p>
        You represent and warrant to Mickle Senior Benefits that, to the best of
        your knowledge and ability, any and all information submitted by you to
        this website is true and accurate.
      </p>
      <h2 className="text-xl font-semibold mt-2">Restrictions On Use</h2>

      <p>
        You may use this site for purposes expressly permitted by this site. You
        may not use this site for any other purpose, including any commercial
        purpose, without express prior written consent of Mickle Senior
        Benefits. For example, you may not display the Mickle Senior Benefits
        name or logo appearing on this website. You agree to cooperate with
        Mickle Senior Benefits in causing any unauthorized co-branding, framing
        or hyper-linking immediately to cease.
      </p>
      <h2 className="text-xl font-semibold mt-2">Proprietary Information</h2>

      <p>
        The content on this site is the proprietary information of Mickle Senior
        Benefits, and Mickle Senior Benefits retains all rights, title, and
        interest in it. Accordingly, the content may not be copied, distributed,
        republished, uploaded, posted, or transmitted in any way without the
        prior written consent of Mickle Senior Benefits, except that you may
        print out a copy of the content solely for your personal use. In doing
        so, you may not remove or alter, or cause to be removed or altered, any
        copyright, trademark, trade name, service mark, or any other proprietary
        notice or legend appearing on any of such content. Modification or use
        of the content except as expressly provided in these Terms and
        Conditions of Use violates the intellectual property rights of Mickle
        Senior Benefits. Neither title nor intellectual property rights are
        transferred to you by access to this site.
      </p>
      <h2 className="text-xl font-semibold mt-2">Links</h2>

      <p>
        This site may be linked to other sites. Such linked sites are not
        maintained by, or related to, Mickle Senior Benefits and such links are
        provided as a service to users and are not sponsored by or affiliated
        with this site or Mickle Senior Benefits. Mickle Senior Benefits has not
        reviewed any such sites and is not responsible for the content of those
        sites. Links are to be accessed at the user’s own risk, and Mickle
        Senior Benefits makes no endorsement, representations or warranties
        about them.
      </p>
      <h2 className="text-xl font-semibold mt-2">
        Third-Party Content & Services
      </h2>

      <p>
        Mickle Senior Benefits may provide links to content of third parties as
        a service to those interested in this information. Mickle Senior
        Benefits does not monitor nor does Mickle Senior Benefits have control
        over any third-party content or third-party websites. Mickle Senior
        Benefits does not endorse or adopt any third-party content and can make
        no representation or warranty as to its accuracy or completeness. Users
        use of these links and third party-content contained therein at their
        own risk.
      </p>
      <h2 className="text-xl font-semibold mt-2">Trademarks and Copyrights</h2>

      <p>
        Mickle Senior Benefits trademarks, service marks and logos appearing in
        this site are the property of Mickle Senior Benefits. Mickle Senior
        Benefits retains all rights with respect to all such content appearing
        in this site.
      </p>
      <h2 className="text-xl font-semibold mt-2">Disclaimer</h2>

      <p>
        YOUR USE OF THIS SITE IS AT YOUR OWN RISK. THIS WEBSITE AND THE
        MATERIALS IN THIS WEBSITE ARE PROVIDED ON AN “AS IS” BASIS WITHOUT
        WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. MICKLE SENIOR
        BENEFITS DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT
        NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
        PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT. MICKLE SENIOR BENEFITS
        DOES NOT REPRESENT OR WARRANT THAT MATERIALS ON THIS WEBSITE ARE
        ACCURATE, COMPLETE, RELIABLE, CURRENT, OR ERROR-FREE. MICKLE SENIOR
        BENEFITS DOES NOT REPRESENT OR WARRANT THAT THIS WEBSITE OR ITS
        SERVER(S) ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. MICKLE SENIOR
        BENEFITS DOES NOT WARRANT THAT THE FUNCTIONS OR CONTENT CONTAINED IN
        THIS SITE WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE
        CORRECTED, OR THAT THIS SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE
        FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. MICKLE SENIOR BENEFITS DOES
        NOT WARRANT OR MAKE ANY REPRESENTATION REGARDING USE, OR THE RESULT OF
        USE, OF THE CONTENT IN TERMS OF ACCURACY, RELIABILITY, OR OTHERWISE. THE
        CONTENT MAY INCLUDE TECHNICAL INACCURACIES OR TYPOGRAPHICAL ERRORS, AND
        MICKLE SENIOR BENEFITS MAY MAKE CHANGES OR IMPROVEMENTS AT ANY TIME.
        YOU, AND NOT MICKLE SENIOR BENEFITS, ASSUME THE ENTIRE COST OF ALL
        NECESSARY SERVICING, REPAIR OR CORRECTION IN THE EVENT OF ANY LOSS OR
        DAMAGE ARISING FROM THE USE OF THIS SITE OR ITS CONTENT. MICKLE SENIOR
        BENEFITS MAKES NO WARRANTIES THAT YOUR USE OF THE CONTENT WILL NOT
        INFRINGE THE RIGHTS OF OTHERS AND ASSUMES NO LIABILITY OR RESPONSIBILITY
        FOR ERRORS OR OMISSIONS IN SUCH CONTENT. REFERENCE TO ANY PRODUCTS,
        SERVICES, PROCESSES, OR OTHER INFORMATION, BY TRADE NAME, TRADEMARK,
        AGENTS, SUPPLIER, OR OTHERWISE DOES NOT CONSTITUTE OR IMPLY ENDORSEMENT,
        SPONSORSHIP OR RECOMMENDATION THEREOF BY MICKLE SENIOR BENEFITS. MICKLE
        SENIOR BENEFITS DOES NOT UNDERTAKE ANY OBLIGATION TO UPDATE SUCH
        INFORMATION AFTER IT IS POSTED OR TO REMOVE SUCH INFORMATION FROM THIS
        SITE IF IT IS NOT, OR IS NO LONGER, ACCURATE OR COMPLETE.
      </p>
      <h2 className="text-xl font-semibold mt-2">Limitation of Liability</h2>

      <p>
        IN NO EVENT SHALL MICKLE SENIOR BENEFITS, ITS SUBSIDIARIES, AFFILIATES,
        LICENSORS, SERVICE PROVIDERS, CONTENT PROVIDERS, EMPLOYEES, AGENTS,
        OFFICERS, AND DIRECTORS BE LIABLE FOR ANY INCIDENTAL, DIRECT, SPECIAL,
        INDIRECT, PUNITIVE, ACTUAL, CONSEQUENTIAL, SPECIAL, OR EXEMPLARY
        DAMAGES, OR ANY OTHER DAMAGES OF ANY KIND, INCLUDING, BUT NOT LIMITED
        TO, LOSS OF USE, LOSS OF PROFITS, OR LOSS OF DATA, WHETHER IN AN ACTION
        IN CONTRACT, TORT (INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE), OR
        OTHERWISE, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OF THIS
        WEBSITE OR THE MATERIALS CONTAINED ON, OR ACCESSED THROUGH, THIS
        WEBSITE.
      </p>
      <h2 className="text-xl font-semibold mt-2">Indemnity</h2>

      <p>
        You will indemnify and hold Mickle Senior Benefits, its subsidiaries,
        affiliates, licensors, content providers, service providers, employees,
        agents, officers, directors, and contractors (the “Indemnified Parties”)
        harmless from any breach of these Terms and Conditions of Use by you,
        including any use of content other than as expressly authorized in these
        Terms and Conditions of Use. You agree that the Indemnified Parties will
        have no liability in connection with any such breach or unauthorized
        use, and you agree to indemnify any and all resulting loss, damages,
        judgments, awards, costs, expenses, and attorneys’ fees of the
        Indemnified Parties in connection therewith. You will also indemnify and
        hold the Indemnified Parties harmless from and against any claims
        brought by third parties arising out of your use of this site.
      </p>
      <h2 className="text-xl font-semibold mt-2">Security</h2>

      <p>
        Any passwords used for this site are for individual use only. You will
        be responsible for the security of your password (if any). Mickle Senior
        Benefits will be entitled to monitor your password and, at its
        discretion, require you to change it. If you use a password that Mickle
        Senior Benefits considers insecure, Mickle Senior Benefits will be
        entitled to require the password to be changed and/or terminate your
        account.
      </p>
      <p>
        You are prohibited from using any services or facilities provided in
        connection with this site to compromise security or tamper with system
        resources and/or accounts. The use or distribution of tools designed for
        compromising security (e.g., password guessing programs, cracking tools
        or network probing tools) is strictly prohibited. If you become involved
        in any violation of system security, Mickle Senior Benefits reserves the
        right to release your details to system administrators at other sites in
        order to assist them in resolving security incidents. Mickle Senior
        Benefits reserves the right to investigate suspected violations of these
        Terms and Conditions of Use.
      </p>
      <p>
        Mickle Senior Benefits reserves the right to fully cooperate with any
        law enforcement authorities or court order requesting or directing
        Mickle Senior Benefits to disclose the identity of anyone posting any
        e-mail messages, or publishing or otherwise making available any
        materials that are believed to violate these Terms and Conditions of
        Use.
      </p>
      <p>
        BY ACCEPTING THIS AGREEMENT YOU WAIVE AND HOLD HARMLESS MICKLE SENIOR
        BENEFITS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY MICKLE
        SENIOR BENEFITS DURING OR AS A RESULT OF ITS INVESTIGATIONS AND/OR FROM
        ANY ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY EITHER MICKLE
        SENIOR BENEFITS OR LAW ENFORCEMENT AUTHORITIES.
      </p>
      <h2 className="text-xl font-semibold mt-2">Applicable Law</h2>

      <p>
        These terms and conditions shall be governed by and construed in
        accordance with the laws of the State of Texas, without regard to its
        choice of law principles to the contrary. You agree that any action at
        law or in equity arising out of or relating to these terms and
        conditions shall be filed only in, and resolved in, the federal or state
        courts in the State of Texas, and you hereby irrevocably and
        unconditionally consent and submit to the exclusive jurisdiction of such
        courts over any suit, action or proceeding arising out of this Notice.
      </p>
      <h2 className="text-xl font-semibold mt-2">Termination</h2>

      <p>
        Notwithstanding any of these terms and conditions, Mickle Senior
        Benefits reserves the right, without notice and in its sole discretion,
        to terminate your license to use this website, and at any time to block
        or prevent future access to and use of this website.
      </p>
      <h2 className="text-xl font-semibold mt-2">Severability</h2>

      <p>
        If any provision of these terms and conditions shall be deemed unlawful,
        void, or for any reason unenforceable, then that provision shall be
        deemed severable from these terms and conditions and shall not affect
        the validity and enforceability of any remaining provisions. If you have
        any questions about this legal notice, or the above terms and conditions
        of use, please contact your legal counsel.
      </p>
      <h2 className="text-xl font-semibold mt-2">Legal Disclaimer</h2>

      <p>
        All text and information provided on or through this site is protected
        by federal copyright law and may not be duplicated, published,
        broadcast, photocopied, faxed, mailed, uploaded, distributed,
        transmitted or redistributed in any manner for any purpose without prior
        written authorization by Mickle Senior Benefits.
      </p>
      <p>
        This site is for informational purposes only and is provided without
        charge to individuals who may seek information regarding insurance.
      </p>
      <p>
        Mickle Senior Benefits does not assume any responsibility for any
        information received or extracted from any links. You agree that if you
        are located in a jurisdiction where free insurance advice or insurance
        quotes are limited or regulated by law, you will limit or refrain from
        use of such advice or quotes as required by law, you will comply with
        such law.
      </p>
    </main>
  </Layout>
)

export default TermsofusePage
